import { useEffect, useState } from "react";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import InquiryForm from "../components/form/InquiryForm";
import Button from "../components/form/Button";
import Wrapper from "../components/grid/Wrapper";
import Grid from "../components/grid/Grid";
import Row from "../components/grid/Row";
import Tiles from "../components/grid/Tiles";
import Cell from "../components/grid/Cell";
import Layout from "../components/layout";
import { Headline, Text } from "../components/text";
import SEO from "../components/seo";
import BubbleNav from "../components/BubbleNav";
import Anchor from "../components/Anchor";
import config from "../../app-config";
import eizoLogo from "../images/logos/eizo.svg";
import fettesbrotLogo from "../images/logos/fettes-brot.svg";
import gentsonLogo from "../images/logos/gentson.svg";
import hereLogo from "../images/logos/here.svg";
import nikeLogo from "../images/logos/nike.svg";
import oranginaLogo from "../images/logos/orangina.svg";
import samsungLogo from "../images/logos/samsung.svg";
import smartLogo from "../images/logos/smart.svg";
import spardaLogo from "../images/logos/sparda-bank.svg";
import telekomLogo from "../images/logos/telekom.svg";
import maxPlanckLogo from "../images/logos/max-planck-institute.png";
import unbabelLogo from "../images/logos/unbabel.svg";
import oranginaVideo from "../videos/case-orangina.mp4";
import carreraVideo from "../videos/case-carrera.mp4";
import telekomVideo from "../videos/case-telekom.mp4";
import eizoVideo from "../videos/case-eizo.mp4";
// import botto from "../images/botto.svg";
import theme, { mediaquery } from "../styles/theme";
import { Link, useStaticQuery, graphql } from "gatsby";
import adaptiveCssValue from "../helpers/adaptiveCssValue";
import VideoTile from "../components/VideoTile";
import Accordion from "../components/Accordion";

const IndexPage = () => {
    const [bubbleNavIndex, setBubbleNavIndex] = useState(0);
    const [sectionCoords, setSectionCoords] = useState([]);
    const [winCenter, setWinCenter] = useState([]);

    const calcSectionCoords = () => {
        const scrollTop = window.pageYOffset;
        setSectionCoords(
            config.navItems.map((item, index) => {
                const elem = document.getElementById(
                    (index !== 0 || item.href !== "/"
                        ? item
                        : config.navItems[index + 1]
                    ).href.replace(/(\/|#)/g, "")
                );
                const rect = elem.getBoundingClientRect();
                return [
                    rect.left + rect.width / 2,
                    index !== 0 || item.href !== "/"
                        ? rect.top + scrollTop + rect.height / 2
                        : (rect.top + scrollTop) / 2
                ];
            })
        );
    };
    useEffect(() => {
        const namespace = "scrollForBubbleNav";
        if (typeof window !== "undefined" && window.__WATCHERS__) {
            window.__WATCHERS__.size.addWatch({
                namespace,
                onchange() {
                    setWinCenter([
                        window.innerWidth / 2,
                        window.innerHeight / 2
                    ]);
                    calcSectionCoords();
                }
            });
        }
        return () => window.__WATCHERS__.size.removeNamespace(namespace);
    }, []);
    useEffect(() => {
        const namespace = "scrollForBubbleNav";
        if (typeof window !== "undefined" && window.__WATCHERS__) {
            window.__WATCHERS__.scroll.addWatch({
                namespace,
                onchange(scrollTop) {
                    let closestIndex = 0,
                        closestDiff = 1000000000;
                    const centerY = winCenter[1] + scrollTop;
                    sectionCoords.forEach(([x, y], index) => {
                        const diff = Math.abs(centerY - y);
                        if (diff < closestDiff) {
                            closestDiff = diff;
                            closestIndex = index;
                        }
                    });
                    setBubbleNavIndex(closestIndex);
                }
            });
        }
        return () => {
            window.__WATCHERS__.scroll.removeNamespace(namespace);
        };
    }, [sectionCoords, winCenter]);
    const caseImageData = useStaticQuery(graphql`
        query MyQuery {
            carrera: file(relativePath: { eq: "cases/carrera-cover.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 512, maxHeight: 512) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            telekom: file(relativePath: { eq: "cases/telekom-cover.png" }) {
                childImageSharp {
                    fluid(maxWidth: 512, maxHeight: 512) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            orangina: file(relativePath: { eq: "cases/orangina-cover.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 512, maxHeight: 512) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            eizo: file(relativePath: { eq: "cases/eizo-cover.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 512, maxHeight: 512) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Home" />
            <BubbleNav
                items={config.navItems}
                css={css`
                    position: fixed;
                    right: ${theme.fluidGrid.mdToX5l.margin};
                    top: 50vh;
                    z-index: 9999;
                `}
                activeIndex={bubbleNavIndex}
            />
            <Wrapper
                css={css`
                    display: flex;
                    flex-direction: column;
                    height: calc(100vh - 5rem);
                    padding-bottom: ${theme.fluidGrid.mdToX5l.margin};
                    margin-bottom: 8em;
                    ${mediaquery(
                        "xl",
                        `
                                height: 100vh;
                    `
                    )}
                `}
            >
                <Row
                    css={css`
                        flex-grow: 1;
                        padding-top: ${adaptiveCssValue(
                            theme.breakpointByAlias["md"],
                            theme.breakpointByAlias["x5l"],
                            6,
                            11
                        )};
                    `}
                >
                    <Cell
                        span={{ sm: 11 / 12, lg: 12 / 12 }}
                        css={css`
                            display: flex;
                            justify-content: center;
                        `}
                    >
                        {/* <img
                            src={botto}
                            alt="Botto"
                            css={css`
                                display: inline-block;
                                max-width: 218px;
                                width: 90%;
                            `}
                        /> */}
                    </Cell>
                </Row>
                <Row>
                    <Cell span={{ sm: 11 / 12 }}>
                        <Headline
                            as={"h1"}
                            css={css`
                                margin-bottom: -0.2em;
                            `}
                        >
                            {/* fucking.digital is a creative studio that offers
                            strategy, branding & production to sweeten the world
                            with the best digital experiences. */}
                            {/* fucking.digital is the feeling of doing digital
                            right. Our stack consists of strategy, branding,
                            design and latest technologies. */}
                            {/* fucking.digital is the feeling of doing digital
                            right. Our tools are strategy, branding, design and
                            latest technologies. */}
                            {/* We are a digitally driven branding and design
                            studio, a bunch of thinkers and doers who pack a
                            punch. */}
                            {/* fucking.digital is the feeling of doing digital
                            right. We are an award winning bunch of thinkers and
                            doers. Our creative work is influenced by our
                            environment and latest technologies. */}
                            {/* Hey! <br />
                            We are fucking.digital <br />A digital creative
                            studio that helps brands to be loved */}
                            {/* Hey! <br /> */}
                            We are fucking.digital <br />A digital creative
                            studio that crafts brand lovers
                        </Headline>
                    </Cell>
                    <Cell span={{ sm: 11 / 12, xl: 10 / 12, xxl: 9 / 12 }}>
                        {/* <Text>
                            It’s the feeling of doing digital right. We are an
                            award winning bunch of thinkers and doers who pack a
                            punch. Our creative work is influenced by our
                            environment and latest technologies.
                        </Text> */}
                        <Button as={Link} to="/#contact">
                            Let’s start a project
                        </Button>
                    </Cell>
                    {/* <Cell span={{ lg: 1 / 12 }}>
                        <div
                            css={css`
                                display: none;
                                align-items: flex-end;
                                position: relative;
                                height: 100%;
                                margin-left: -100%;

                                ${mediaquery(
                                    "lg",
                                    `
                                    display: flex;
                                    `
                                )}
                            `}
                        >
                            <div
                                css={css`
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    display: block;
                                    width: 100%;
                                    height: 0;
                                    padding-bottom: 100%;
                                    vertical-align: middle;
                                `}
                            >
                                <img
                                    src={stamp}
                                    css={css`
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        display: block;
                                    `}
                                    alt="fucking.digital — fucking.awesome"
                                />
                                <div
                                    css={css`
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        text-align: center;
                                        vertical-align: middle;
                                        font-size: 1.6em;
                                    `}
                                >
                                    ↓
                                </div>
                            </div>
                        </div>
                    </Cell> */}
                </Row>
                {/* <Text css={{ maxWidth: "50%" }}>
                Benefit from our more than 15 years of experience in the digital
                sector. Helping brands and startups to launch their websites,
                apps, online shops and the like.
            </Text> */}
            </Wrapper>
            {/* <Wrapper>
            <Row>
                <Cell
                    span={{
                        lg: 4 / 6
                    }}
                    offset={{
                        lg: 1 / 6
                    }}
                >
                    <Text>
                        Our clients and their customers benefit from our team of
                        thinkers and doers, whose work is driven by creative
                        strategies brought to life by the latest technologies.
                    </Text>
                </Cell>
            </Row>
        </Wrapper> */}
            <Wrapper>
                <Grid>
                    {/* <Row
                        css={css`
                            margin-bottom: 8em;
                        `}
                    >
                        <Cell
                            span={{
                                sm: 11 / 12,
                                lg: 3 / 6
                            }}
                            offset={{
                                lg: 5 / 12
                            }}
                        >
                            <Headline>TODO: Why</Headline>
                            <Text>
                                The digital sector opens up a wide range of
                                possibilities for action. However, it is just as
                                sensitive when it comes to placing and
                                maintaining a company’s brand. Despite all the
                                risks and opportunities, we help our clients
                                benefit from the right decisions in the long
                                term.
                            </Text>
                        </Cell>
                    </Row> */}
                    <Row
                        css={css`
                            margin-bottom: 8em;
                        `}
                    >
                        <Cell
                        // span={{
                        //     xl: 10 / 12,
                        //     x3l: 4 / 6
                        // }}
                        // offset={{
                        //     x3l: 1 / 6
                        // }}
                        >
                            <Headline>
                                <Anchor
                                    id={config.navItems[1].href.replace(
                                        /^\/#?/,
                                        ``
                                    )}
                                >
                                    Selected Work
                                </Anchor>
                            </Headline>
                            <Tiles cols={{ sm: 1, lg: 2 }}>
                                {[
                                    {
                                        color: theme.colors.background,
                                        bgColor: "#1e3480",
                                        videoSrc: oranginaVideo,
                                        imageSrc:
                                            caseImageData.orangina
                                                .childImageSharp.fluid,
                                        imageAlt:
                                            "Orangina Corporate and Commercial Website",
                                        description: {
                                            title: "Orangina",
                                            text: "Corporate Website"
                                            // position: ["left", "top"]
                                        }
                                    },
                                    {
                                        color: theme.colors.background,
                                        bgColor: "#df0075",
                                        videoSrc: telekomVideo,
                                        imageSrc:
                                            caseImageData.telekom
                                                .childImageSharp.fluid,
                                        imageAlt:
                                            "Deutsche Telekom App “WeCare” for Android and iOS",
                                        description: {
                                            title: "Deutsche Telekom",
                                            text: "“We Care” App",
                                            position: ["left", "bottom"]
                                        }
                                    },
                                    {
                                        bgColor: "#f7f7f7",
                                        videoSrc: carreraVideo,
                                        imageSrc:
                                            caseImageData.carrera
                                                .childImageSharp.fluid,
                                        imageAlt:
                                            "Carrera E-Commerce Website/Online Shop",
                                        description: {
                                            title: "Carrera",
                                            text: `E\u2011Commerce`,
                                            position: ["left", "bottom"]
                                        }
                                    },
                                    {
                                        color: "#ffffff",
                                        bgColor: "#050415",
                                        videoSrc: eizoVideo,
                                        imageSrc:
                                            caseImageData.eizo.childImageSharp
                                                .fluid,
                                        imageAlt:
                                            "EIZO Microsite Product Launch",
                                        description: {
                                            title: "EIZO",
                                            text: "Product Launch Website",
                                            position: ["left", "bottom"]
                                        }
                                    }
                                ].map(vT => (
                                    <VideoTile
                                        key={
                                            vT.description.title +
                                            vT.description.text
                                        }
                                        {...vT}
                                    />
                                ))}
                            </Tiles>
                        </Cell>
                    </Row>
                </Grid>
            </Wrapper>
            <Wrapper
                css={css`
                    margin-bottom: 8em;
                `}
            >
                <Grid>
                    <Row>
                        <Cell
                            span={{
                                sm: 11 / 12,
                                lg: 4 / 6
                            }}
                            offset={{
                                lg: 2 / 12
                            }}
                        >
                            <Headline>
                                <Anchor
                                    id={config.navItems[2].href.replace(
                                        /^\/#?/,
                                        ``
                                    )}
                                >
                                    What we do
                                </Anchor>
                            </Headline>
                            <Text>
                                {/* Actually, we do not sell individual services,
                                but believe that a long-term partnership pays
                                off best. Think of it as compound interest. With
                                the goal in mind, sometimes the route to it can
                                still change. That’s why we like to remain agile
                                and adaptable, so that we always make the
                                greatest impact in strengthening your brand with
                                the right skill set. We love helping brands
                                grow. This leads us not only to unique designs,
                                but also to intelligent and innovative
                                solutions. */}
                                {/* We love helping brands unleash their potential
                                and increase their impact on their audience. */}
                                Our goal is to turn people into brand lovers. We
                                follow the digital-first approach. Convincing
                                digital experiences result from a well-defined
                                brand. With our toolset we create branded
                                websites, apps and social media channels that
                                convert, whether their purpose is information,
                                e&#8209;commerce, entertainment or
                                communication.
                                {/* Wir haben Methoden entwickelt, mit denen wir großartige digitale Experiences kreieren können.
                                This is why developing a new
                                brand or enhancing an existing one is the first
                                step. This is followed by the development of a
                                product or service by creating a website or app
                                from concept to launch. Our tools are as
                                follows: */}
                            </Text>
                            <Accordion
                                css={css`
                                    > h3 {
                                        position: relative;
                                        padding-top: 0.75em;

                                        &:after {
                                            content: "";
                                            position: absolute;
                                            bottom: calc(100% - 1px);
                                            left: 0;
                                            width: 100%;
                                            border-top: solid 1px currentColor;
                                        }

                                        &:first-of-type:after {
                                            display: none;
                                        }
                                    }
                                    > div {
                                        margin: 0 0 0.75em;
                                        padding: 0;
                                    }
                                `}
                            >
                                {[
                                    {
                                        main: "Strategy",
                                        sub: [
                                            "Analytics",
                                            "Consulting",
                                            "User Research",
                                            "Innovation & Ideation",
                                            "Business Integration",
                                            "Content Strategy",
                                            "Service Design",
                                            "SEO Strategy"
                                        ]
                                    },
                                    {
                                        main: "Branding",
                                        sub: [
                                            "Analytics",
                                            "Consulting",
                                            "Naming",
                                            "Monitoring",
                                            "Marketing",
                                            "Visual Identity",
                                            "Finding your Why"
                                        ]
                                    },
                                    {
                                        main: "Websites & Apps",
                                        sub: [
                                            "Corporate Websites",
                                            "Product Launch",
                                            "Microsite",
                                            "Landing Page",
                                            "Magazines",
                                            "E-Commerce",
                                            "Applications"
                                        ]
                                    },
                                    {
                                        main: "Product Design",
                                        sub: [
                                            "Agile Development",
                                            "Feature Implementation",
                                            "Consulting",
                                            "API Design",
                                            "Service Design",
                                            "Monitoring"
                                        ]
                                    },
                                    {
                                        main: "Creation",
                                        sub: [
                                            "Content",
                                            "Creative Concept",
                                            "Corporate Design",
                                            "Interface Design",
                                            "UX Design",
                                            "Motion Design",
                                            "Photography"
                                        ]
                                    },
                                    {
                                        main: "Coding",
                                        sub: [
                                            "Prototyping",
                                            "Backend-Development",
                                            "Frontend-Development",
                                            "SEO",
                                            "Agile Development",
                                            "APIs",
                                            "Serverless",
                                            "Continuous Integration"
                                        ]
                                    }
                                    // {
                                    //     main: "Improving",
                                    //     sub: ["Monitoring", "SEO Adaptions"]
                                    // }
                                ].map(item => (
                                    <Accordion.Item
                                        title={item.main}
                                        key={item.main}
                                    >
                                        {item.sub.map(subitem => (
                                            <span
                                                key={subitem}
                                                css={css`
                                        display: inline-block;
                                            // background-color: ${theme.colors.midGrey};
                                            // font-size: 0.6em;
                                            // font-weight: 700;
                                            // letter-spacing: 0.1em;
                                            // color: ${theme.colors.background};
                                            // border-radius: 6em;
                                            // padding: 0.6em 1.6em;
                                            // margin: 0 1em 1em 0;
                                            // transition: background-color .2s;
                                            font-size: 0.8em;
                                            letter-spacing: 0.04em;
                                            color: ${theme.colors.darkGrey};
                                            background-color: rgba(255,255,255,0.5);
                                            border: solid 1px ${theme.colors.midGrey3};
                                            border-radius: 6em;
                                            padding: 0.2em 1.6em;
                                            margin: 0 .6em 1em 0;
                                            transition: all .2s;
                                            white-space: nowrap;

                                            &:hover {
                                                color: inherit;
                                                border-color: ${theme.colors.dirtyWhite};
                                                background-color: ${theme.colors.dirtyWhite};
                                                
                                            }
                                        `}
                                            >
                                                {subitem}
                                            </span>
                                        ))}
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Cell>
                    </Row>
                </Grid>
            </Wrapper>
            <Wrapper>
                <Grid>
                    <Row>
                        <Cell
                            span={{
                                sm: 11 / 12,
                                lg: 4 / 6
                            }}
                            offset={{
                                lg: 1 / 6
                            }}
                        >
                            <Headline>Brand Experience</Headline>
                            <Text>
                                We are proud to have been working with the
                                following brands over the last 15 years. We
                                learn with every project and gladly give back
                                our knowledge.
                            </Text>
                        </Cell>
                    </Row>
                    <Row
                        css={css`
                            margin-bottom: 8em;
                        `}
                    >
                        <Cell
                            span={{
                                sm: 11 / 12,
                                lg: 4 / 6
                            }}
                            offset={{ lg: 1 / 6 }}
                        >
                            <Row
                                css={css`
                                    margin: calc(-1.077rem + -2.5642vw);

                                    > * {
                                        margin-bottom: -3em;
                                    }
                                `}
                            >
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={nikeLogo} alt="Nike" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={eizoLogo} alt="EIZO" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={smartLogo} alt="smart" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={oranginaLogo} alt="Orangina" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={hereLogo} alt="here" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={samsungLogo} alt="Samsung" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img
                                        src={maxPlanckLogo}
                                        alt="Max Planck Institute for the History of Science"
                                    />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={gentsonLogo} alt="GENTSØN" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img
                                        src={telekomLogo}
                                        alt="Deutsche Telekom"
                                    />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img src={unbabelLogo} alt="Unbabel" />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img
                                        src={fettesbrotLogo}
                                        alt="Fettes Brot"
                                    />
                                </Cell>
                                <Cell
                                    span={{
                                        sm: 1 / 2,
                                        md: 1 / 3,
                                        lg: 1 / 4
                                    }}
                                >
                                    <img
                                        src={spardaLogo}
                                        alt="Sparda-Bank West"
                                    />
                                </Cell>
                            </Row>
                        </Cell>
                    </Row>
                </Grid>
            </Wrapper>
            <Wrapper>
                <Grid>
                    <Row
                        css={css`
                            margin-bottom: 8em;
                        `}
                    >
                        <Cell
                            span={{
                                sm: 11 / 12,
                                lg: 4 / 6
                            }}
                            offset={{
                                lg: 1 / 6
                            }}
                        >
                            <Headline>
                                <Anchor
                                    id={config.navItems[3].href.replace(
                                        /^\/#?/,
                                        ``
                                    )}
                                >
                                    {config.navItems[3].title}
                                </Anchor>
                            </Headline>
                            <Text>
                                fucking.digital is an award winning bunch of
                                thinkers and doers. Our creative work is
                                influenced by our environment and latest
                                technologies. We believe in the power of
                                collaboration, shared knowledge and steady
                                improvement. We win or learn.
                                {/* collaboration may be challenging at times. But this is what makes products superior. */}
                            </Text>
                            <Text>
                                Our office is{" "}
                                <a
                                    href="https://fckd.tl/office"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    located in Berlin
                                </a>{" "}
                                . Our minds are cosmopolitan.
                            </Text>
                        </Cell>
                    </Row>
                </Grid>
            </Wrapper>
            <Wrapper>
                <Grid>
                    <Row
                        css={css`
                            margin-bottom: 8em;
                        `}
                    >
                        <Cell
                            span={{
                                sm: 11 / 12,
                                lg: 4 / 6
                            }}
                            offset={{
                                lg: 1 / 6
                            }}
                        >
                            <Headline>
                                <Anchor
                                    id={config.navItems[4].href.replace(
                                        /^\/#?/,
                                        ``
                                    )}
                                >
                                    Get in touch
                                </Anchor>
                            </Headline>
                            <Text>
                                Whether you want to create a digital product
                                from scratch, improve on your existing one or
                                are unsure where to start. We would like to know
                                what you are working on and what we can help you
                                with. Let’s get in touch and find out!
                            </Text>
                            <InquiryForm />
                        </Cell>
                    </Row>
                </Grid>
            </Wrapper>
        </Layout>
    );
};

export default IndexPage;
